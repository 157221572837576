.home-container {
    @apply flex h-full flex-col items-center justify-center;

    .content {
        @apply relative mx-auto flex w-full flex-col space-y-8 lg:space-y-16 xl:space-y-28;

        .__upcoming,
        .__featured,
        .__popular {
            @apply space-y-2 lg:space-y-8;
        }

        .actions {
            @apply flex flex-col space-y-8 px-8 sm:px-16 md:flex-row md:space-x-8 md:space-y-0 lg:space-x-12 lg:px-0;

            .action {
                @apply flex-1 space-y-2;

                .icon-container {
                    @apply flex items-center justify-center text-akashic-blue-500;
                }

                h3 {
                    @apply text-center text-lg text-akashic-blue-500 md:text-xl;
                }

                p {
                    @apply text-center text-sm text-akashic-white-50;
                }
            }
        }
    }
}

.legal-page {
    @apply prose mx-auto text-akashic-white-50 lg:prose-xl;

    h1 {
        @apply tracking-tighter text-akashic-white-50;
    }

    h2 {
        @apply tracking-tighter text-akashic-white-50;
    }

    .list-header {
        @apply font-semibold text-akashic-white-50 underline;
    }

    .sub-list-header {
        @apply text-akashic-white-50 underline;
    }

    strong {
        @apply text-akashic-white-50;
    }

    a {
        @apply text-bright-turquoise-500 underline;
    }
}
